
import { Inject } from 'inversify-props';
import { $enum } from 'ts-enum-util';
import { Component, Mixins } from 'vue-property-decorator';

import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import CustomDateRangePicker from '@/modules/common/components/ui-kit/custom-date-range-picker.vue';
import DEFAULT_LOS from '@/modules/document-filters/constants/default-los.constant';
import DownloadReportForm, { DownloadReportControlMixin, FilterParam } from '@/modules/common/components/download-report-form.vue';
import CurrencySwitcher from '@/modules/common/components/currency-switcher.vue';
import Item from '@/modules/common/interfaces/item.interface';
import RatesFilterItemsMixin from '../mixins/rates-filters.mixin';
import { RatesDownloadExcelForm } from '../rates-api.service';
import PRICE_SHOWN from '../constants/price-shown.constant';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '../rates-analysis-filters.service';

const DAY_RANGE_PRESETS = [30, 60, 90];

const FILTER_SELECT_ALL = (providerList: string[]) => providerList.filter(p => p !== 'cheapest');

@Component({
    components: {
        DownloadReportForm,
    },
})
export default class RatesDownloadExcelPopup extends Mixins(RatesFilterItemsMixin, DownloadReportControlMixin) {
    @Inject(RatesAnalysisFiltersServiceS)
    private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;

    public form: RatesDownloadExcelForm = {} as RatesDownloadExcelForm;
    private isLoading = false;
    private oldCompareTo = '';
    private oldProviderList: string[] = [];

    public get attrs() {
        const { filters, customColumns } = this;
        const { properties, buttons } = this;
        const { isLoading } = this;

        return {
            filters,
            customColumns,
            properties,
            buttons,
            isLoading,
            dataType: this.isAnalysisMode ? 'Analysis' : 'Rates',
        };
    }

    protected get isAnalysisMode() {
        return this.$route.name!.includes('.analysis');
    }

    private get isRangeValid() {
        const { monthrange } = this.form;

        if (!monthrange) return false;

        const maxRange = 365;
        const [start, end] = monthrange.map((date: string) => new Date(date));

        if (!start || !end) return false;

        const diff = Math.abs(start.getTime() - end.getTime());
        const diffDays = Math.ceil(diff / (1000 * 3600 * 24));

        return diffDays <= maxRange;
    }

    private get isDownloadDisabled() {
        return !this.validateForm();
    }

    private get buttons() {
        const buttons = [
            {
                label: 'Download now',
                onClick: () => this.downloadExcel(),
                isDisabled: this.isDownloadDisabled,
            },
        ];

        if (!this.isAnalysisMode) {
            buttons.push(
                {
                    label: 'Send to email',
                    onClick: () => this.downloadExcel(true, false),
                    isDisabled: this.isDownloadDisabled,
                },
                {
                    label: 'On Demand and Send',
                    onClick: () => this.downloadExcel(false, true),
                    isDisabled: this.isDownloadDisabled,
                },
            );
        }

        return buttons;
    }

    private get properties() {
        return [
            {
                label: 'Days count (from today)',
                key: 'daysCount',
                default: -1,
                component: CustomSelect,
                props: {
                    items: DAY_RANGE_PRESETS
                        .map(days => ({
                            name: `Next ${days} days`,
                            value: days,
                        }))
                        .concat([{
                            name: 'Custom range',
                            value: -1,
                        }]),
                },
                handlers: {
                    input: this.onDaysCountChange,
                },
            },
            {
                label: 'Date range',
                key: 'monthrange',
                component: CustomDateRangePicker,
                default: [],
                props: {
                    maxDays: 365,
                },
                handlers: {
                    input: this.onDateRangeChange,
                },
            },
            {
                label: 'Currency',
                key: 'displayCurrency',
                component: CurrencySwitcher,
                default: this.userSettingsService.displayCurrency,
            },
        ];
    }

    private onDaysCountChange(value: any) {
        if (value === -1) {
            this.form.monthrange = [];
            return;
        }

        const startDate = new Date(new Date().toISOString().split('T')[0]);
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + value);

        this.form.monthrange = [startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]];
    }

    private onDateRangeChange(range: [string, string]) {
        const today = new Date();
        const start = new Date(range[0]);

        const isStartToday = start.getDate() === today.getDate()
            && start.getMonth() === today.getMonth()
            && start.getFullYear() === today.getFullYear();

        if (!isStartToday) {
            this.form.daysCount = -1;
            return;
        }

        const end = new Date(range[1]);
        const deltaDays = Math.ceil(Math.abs(start.getTime() - end.getTime()) / (1000 * 3600 * 24)) + 1;

        if (DAY_RANGE_PRESETS.includes(deltaDays)) {
            this.form.daysCount = deltaDays;
        } else {
            this.form.daysCount = -1;
        }
    }

    private get customColumns() {
        if (this.isAnalysisMode) return [];

        const isCheapestSelected = Array.isArray(this.form.provider)
            ? this.form.provider.includes('cheapest')
            : this.form.provider === 'cheapest';

        return [
            {
                key: 'market_demand',
                label: 'Market Demand',
                default: true,
            },
            {
                key: 'occupancy',
                label: 'Occupancy',
                default: true,
            },
            {
                key: 'rank',
                label: 'Rank',
                default: true,
            },
            {
                key: 'diff_delta',
                label: 'Diff #',
                default: !isCheapestSelected,
                disabled: isCheapestSelected,
            },
            {
                key: 'diff_precent',
                label: 'Diff %',
                default: !isCheapestSelected,
                disabled: isCheapestSelected,
            },
            {
                key: 'median',
                label: 'Median',
                default: !isCheapestSelected,
                disabled: isCheapestSelected,
            },
            {
                key: 'mealType',
                label: 'Meal Type',
                default: !isCheapestSelected,
                disabled: isCheapestSelected,
            },
            {
                key: 'roomType',
                label: 'Room Type',
                default: !isCheapestSelected,
                disabled: isCheapestSelected,
            },
            {
                key: 'roomName',
                label: 'Room Name',
                default: !isCheapestSelected,
                disabled: isCheapestSelected,
            },
        ];
    }

    private get compsetFilter() {
        const defaultCompset = this.compsetsService.currentCompset
            ? this.compsetsService.currentCompset.id
            : this.compsetTypeItems?.[0]?.value || null;

        return {
            label: 'Comp Set',
            key: 'compsetId',
            options: this.compsetTypeItems,
            default: defaultCompset,
        };
    }

    private get providerFilter() {
        const { compsetId } = this.form;
        const { provider: globalProvider } = this.ratesService.settings;
        const provider = (this.form.provider || []);

        const EXCLUDE_PROVIDER = this.isAnalysisMode ? ['all', 'cheapest'] : ['all'];

        const compset = this.compsetsService.getCompset(compsetId)
            || this.compsetsService.currentCompset;

        const providerItems = (compset?.rateProviders || [])
            .filter(p => !EXCLUDE_PROVIDER.includes(p));

        const defaultProvider = !EXCLUDE_PROVIDER.includes(globalProvider!)
            ? [globalProvider!]
            : [this.ratesService.settings.provider || providerItems[0]];

        const haveInvalidProviders = this.isAnalysisMode
            ? !providerItems.includes(provider as string)
            : (provider as string[]).some(p => !providerItems.includes(p));

        const providerDifference = !this.isAnalysisMode
            ? (provider as string[]).filter(p => !this.oldProviderList.includes(p))
            : [];

        const haveCheapestChannel = !this.isAnalysisMode
            ? providerDifference.includes('cheapest')
            : false;

        const options = this.providersService.toItemsList(providerItems);

        if (haveInvalidProviders) {
            this.form.provider = this.isAnalysisMode
                ? providerItems[0]
                : (provider as string[]).filter(p => providerItems.includes(p));
        }

        if (haveCheapestChannel) {
            this.disableNonCheapestColumns();
            this.form.provider = ['cheapest'];
        } else if (provider.includes('cheapest')) {
            this.form.provider = (provider as string[]).filter(p => p !== 'cheapest');
        }

        this.oldProviderList = [...provider];

        return {
            label: 'Source',
            key: 'provider',
            options,
            default: this.isAnalysisMode
                ? defaultProvider[0]
                : defaultProvider,
            multiselect: !this.isAnalysisMode,
            valuesOnly: true,
            filterSelectAll: FILTER_SELECT_ALL,
        };
    }

    private get posFilter() {
        return {
            label: 'POS',
            key: 'pos',
            options: this.posItems,
            default: this.posItems[0]
                ? this.pos || this.posItems[0].value
                : 'US',
        };
    }

    private get isShortFilter() {
        const { provider } = this.form;
        return provider ? provider.includes('google') : false;
    }

    private get priceTypeFilter() {
        const defaultPriceType = this.priceTypeItems
            .find(p => p.value === this.priceType) || this.priceTypeItems[0];

        return {
            label: 'Price',
            key: 'priceType',
            options: this.priceTypeItems,
            default: this.isAnalysisMode
                ? defaultPriceType.value
                : [defaultPriceType],
            disabled: this.isShortFilter,
            multiselect: !this.isAnalysisMode,
        };
    }

    private get losFilter() {
        const { los } = this.documentFiltersService.settings;
        const values = this.form;

        const compset = this.compsetsService.getCompset(values.compsetId as string)
            || this.compsetsService.currentCompset;

        const choosenProviders = !this.isAnalysisMode
            ? (values.provider || []) as string[]
            : values.provider as string;
        const haveDisabledProviders = Array.isArray(choosenProviders)
            ? choosenProviders.some(p => this.providersService.isDisabledProvider(p))
            : choosenProviders === 'cheapest';

        const options = haveDisabledProviders
            ? compset!.los
            : DEFAULT_LOS;

        const defaultLos = options.includes(los!) ? los! : options[0];
        const choosenLos = (values.los || defaultLos) as number;

        if (!options.includes(choosenLos)) {
            values.los = defaultLos;
        }

        return {
            label: 'LOS',
            key: 'los',
            default: defaultLos,
            options: options.map(l => ({
                name: `LOS${l}`,
                value: l,
            })),
        };
    }

    private get occupancyFilter() {
        const { provider } = this.form;
        const isNumberOfGuestDisabled = Array.isArray(provider)
            ? provider.some(p => this.providersService.isDisabledProvider(p)) || false
            : this.providersService.isDisabledProvider(provider);

        return {
            label: 'Number of guests',
            key: 'numberOfGuests',
            options: this.numberOfGuestsItems,
            default: this.numberOfGuests || this.numberOfGuestsItems[0].value,
            disabled: this.isShortFilter || isNumberOfGuestDisabled,
        };
    }

    private get roomTypeFilter() {
        const { roomTypeId } = this.ratesFiltersService.settings;
        const roomTypeItems = this.roomTypeItems
            .filter(i => this.isAnalysisMode || i.value !== -1);

        const defaultRoomTypes = roomTypeId !== -1
            ? roomTypeItems.filter(i => i.value === roomTypeId)
            : [...roomTypeItems];
        const defaultRoomType = defaultRoomTypes[0] && defaultRoomTypes[0].value;

        return {
            label: 'Room Type',
            key: 'roomTypeId',
            options: this.isAnalysisMode
                ? this.roomTypeItems
                : roomTypeItems,
            default: !this.isAnalysisMode
                ? defaultRoomTypes
                : defaultRoomType,
            disabled: this.isShortFilter,
            multiselect: !this.isAnalysisMode,
        };
    }

    private get mealTypeFilter() {
        const { mealTypeId } = this.ratesFiltersService.settings;
        const mealTypeItems = this.mealTypesService.mealTypes
            .filter(i => this.isAnalysisMode || i.id !== -1)
            .map(item => ({
                id: item.id,
                name: item.displayName,
                value: item.name,
            }));

        const defaultMealTypes = mealTypeId !== -1
            ? mealTypeItems.filter(i => i.id === mealTypeId)
            : [...mealTypeItems];
        const defaultMealType = defaultMealTypes[0] && defaultMealTypes[0].value;

        return {
            label: 'Meal Type',
            key: 'mealTypeId',
            options: mealTypeItems,
            default: !this.isAnalysisMode
                ? defaultMealTypes
                : defaultMealType,
            disabled: this.isShortFilter,
            multiselect: !this.isAnalysisMode,
        };
    }

    private get priceShownFilter() {
        const priceShownItems = $enum(PRICE_SHOWN)
            .getValues()
            .filter(e => e !== 'Calculated')
            .map(value => ({ value, name: value }));

        const { priceShown } = this.documentFiltersService;

        return {
            label: 'Price shown',
            key: 'priceShown',
            default: priceShown || PRICE_SHOWN.SHOWN,
            options: priceShownItems,
        };
    }

    private get filters() {
        const filters = [
            this.compsetFilter,
            this.providerFilter,
            this.posFilter,
            this.priceTypeFilter,
            this.losFilter,
            this.occupancyFilter,
            this.roomTypeFilter,
            this.mealTypeFilter,
            this.priceShownFilter,
        ];

        if (this.isAnalysisMode) {
            this.updateAnalysisFilters(filters as FilterParam[]);
        }

        return filters;
    }

    private updateAnalysisFilters(filters: FilterParam[]) {
        const { comparsionTypeItems } = this;
        const { comparisonValues: mainCompareValues, comparisonKey } = this.ratesAnalysisFiltersService;
        let { compareValue } = this.form;
        const oldCompareValue = JSON.stringify(compareValue);

        const defaultCompareFilter = comparisonKey || 'diffDays';
        const compareTo = (this.form.compareTo! || defaultCompareFilter) as keyof RatesDownloadExcelForm;
        const isCompareKeyChanged = this.oldCompareTo !== compareTo;

        let mainValue = this.form[compareTo] as string | number;

        const defaultCompareValue = mainCompareValues
            ? mainCompareValues.map(i => i.value)
            : [1];

        if (compareTo === 'mealTypeId') {
            mainValue = this.mealTypesService.getMealType(mainValue)?.id!;
        }

        let compareItems = [] as Item[];

        if (compareTo !== 'provider') {
            compareItems = this.ratesAnalysisFiltersService
                .getFilterItemsExceptValue(compareTo, mainValue);
        } else {
            const compset = this.compsetsService.getCompset(this.form.compsetId!)
                || this.compsetsService.currentCompset;

            compareItems = this.providersService
                .toItemsList((compset?.rateProviders || [])
                    .filter(p => p !== mainValue && !['all', 'cheapest'].includes(p)));
        }

        if (compareValue) {
            compareValue = !isCompareKeyChanged
                ? compareValue.filter(i => i !== mainValue)
                : compareItems.slice(0, 2).map(i => i.value);
        } else {
            compareValue = defaultCompareValue;
        }

        compareValue = compareValue
            .filter(i => compareItems.some(c => c.value === i));

        const isCompareValueChanged = JSON.stringify(compareValue) !== oldCompareValue;

        this.oldCompareTo = compareTo;

        if (isCompareKeyChanged || isCompareValueChanged) {
            this.form.compareValue = compareValue;
            this.form.compareTo = compareTo;
        }

        filters.push(
            { divider: true, count: 6 } as any,
            {
                label: 'Compare to',
                key: 'compareTo',
                options: comparsionTypeItems,
                default: defaultCompareFilter,
            },
            {
                label: 'Compare Value',
                key: 'compareValue',
                options: compareItems,
                default: compareValue,
                maxSelected: 2,
                multiselect: true,
                valuesOnly: true,
            },
        );
    }

    private get comparsionTypeItems() {
        const compareFilter = this.ratesAnalysisFiltersService.filterList;
        const { compareTo } = this.form;
        const provider = this.form.provider as string;

        if (provider === 'cheapest') {
            if (compareTo === 'diffDays') {
                this.form.compareTo = 'provider';
            }

            return compareFilter
                .filter(item => item.value !== 'diffDays');
        }

        return compareFilter;
    }

    private getPreparedForm() {
        if (this.isAnalysisMode) {
            // NOTE: Since for compare mode we use <CustomSelect /> component
            //       that returns values directly instead of Item[] like <CustomMultiSelect />
            //       so we must convert back these values to Item[] type.
            const { roomTypeId, mealTypeId, priceType } = this.form as unknown as {
                roomTypeId: number;
                mealTypeId: string;
                priceType: string;
            };

            const roomTypeName = this.roomTypeItems.find(i => i.value === roomTypeId)!.name;

            const roomTypeParams = roomTypeId === -1
                ? this.roomTypeItems.filter(i => i.value !== -1)
                : [{ name: roomTypeName, value: roomTypeId }];
            const mealTypeParams = mealTypeId === 'any'
                ? this.mealTypesService.mealTypes
                    .filter(i => i.id !== -1)
                    .map(i => ({ value: i.name, name: i.displayName }))
                : [{ name: mealTypeId, value: mealTypeId }];

            return {
                ...this.form,
                roomTypeId: roomTypeParams,
                mealTypeId: mealTypeParams,
                priceType: [{ name: priceType, value: priceType }],
                provider: this.isAnalysisMode
                    ? [this.form.provider]
                    : this.form.provider,
            } as RatesDownloadExcelForm;
        }

        return {
            ...this.form,
            daysCount: undefined,
        };
    }

    private validateForm() {
        const { compareValue, monthrange } = this.form;
        const { pos, los, provider } = this.form;
        const { roomTypeId, mealTypeId, priceType } = this.form;

        if (compareValue && !compareValue.length) {
            this.triggerFormError('Please select at least one value for compare');
            return false;
        }

        if (monthrange && !monthrange.length) {
            this.triggerFormError('Please select date range');
            return false;
        }

        if (!this.isRangeValid) {
            this.triggerFormError('The date range is invalid');
            return false;
        }

        if (!pos) {
            this.triggerFormError('Please select POS');
            return false;
        }

        if (!los) {
            this.triggerFormError('Please select LOS');
            return false;
        }

        if (!provider) {
            this.triggerFormError('Please select Source');
            return false;
        }

        const isRoomTypeValid = typeof roomTypeId === 'number'
            ? !!roomTypeId
            : !!roomTypeId?.length;
        if (!isRoomTypeValid) {
            this.triggerFormError('Please select Room Type');
            return false;
        }

        if (!mealTypeId || !mealTypeId.length) {
            this.triggerFormError('Please select Meal Type');
            return false;
        }

        if (!priceType || !priceType.length) {
            this.triggerFormError('Please select Price shown');
            return false;
        }

        return true;
    }

    private async downloadExcel(toEmail = false, onDemand = false) {
        try {
            this.isLoading = true;
            const form = this.getPreparedForm();
            await this.ratesService.getExcel(form, toEmail, onDemand);

            if (toEmail) {
                this.triggerFormMessage('Report sent');
            } else {
                this.closeForm();
            }
        } catch (_) {
            this.triggerFormError('There is no data. Try change the filters.');
        } finally {
            this.isLoading = false;
        }
    }

    private disableNonCheapestColumns() {
        [
            'diff_delta',
            'diff_precent',
            'median',
            'mealType',
            'roomType',
            'roomName',
        ].forEach(key => {
            if (!(key in this.form.columns)) return;
            this.form.columns[key as keyof RatesDownloadExcelForm['columns']] = false;
        });
    }
}
