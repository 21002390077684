
import { Component, Vue, Prop } from 'vue-property-decorator';
import { DatePicker } from 'element-ui';

@Component({
    components: { DatePicker },
})
export default class CustomDateRangePicker extends Vue {
    @Prop({
        type: Number,
        required: true,
    })
    maxDays!: number;

    @Prop({
        type: Array,
        default: () => [],
    })
    value!: [string, string];

    private minDate: Date | null = null;
    private maxDate: Date | null = null;

    props = {
        type: 'daterange',
        rangeSeparator: '-',
        startPlaceholder: 'Start Month',
        endPlaceholder: 'End month',
        format: 'MMM dd, yyyy',
        valueFormat: 'yyyy-MM-dd',
        clearable: false,
        pickerOptions: {
            disabledDate: this.disableDueTime.bind(this),
            onPick: this.onPick.bind(this),
        },
    };

    onPick({ minDate, maxDate }: { minDate: Date, maxDate: Date }) {
        this.minDate = minDate;
        this.maxDate = maxDate;
    }

    disableDueTime(time: Date) {
        if (this.maxDate) {
            return false;
        }

        if (this.minDate) {
            const dueDate = new Date(this.minDate);
            dueDate.setDate(this.minDate.getDate() + this.maxDays);
            return time.getTime() > dueDate.getTime();
        }

        return false;
    }

    get modelValue() {
        return this.value;
    }

    set modelValue(value: [string, string]) {
        this.$emit('input', value);
    }
}
